import React, {
  useState, useEffect, useCallback
} from 'react'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow
} from '@mui/material'
import { getKYCs } from 'api/kyc'
import { useNavigate } from 'react-router-dom'

export default function KYCTable({ refresh }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [KYCs, setKYCs] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [totalKYCs, setTotalKYCs] = useState(0)

  const loadData = useCallback(async () => {
    dispatch(showLoader())
    try {
      const response = await getKYCs({ limit: pageSize, page: page + 1 })
      setKYCs(response.data.docs)
      setTotalKYCs(response.data.totalDocs)
    } catch (err) {
      console.log(err)
    }
    dispatch(hideLoader())
  }, [page, pageSize, dispatch])

  useEffect(() => {
    refresh.current = loadData
  }, [loadData, refresh])

  useEffect(() => {
    loadData()
  }, [loadData, page, pageSize])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="20%">Email</TableCell>
          <TableCell width="10%">Country</TableCell>
          <TableCell width="15%">Date Submitted</TableCell>
          <TableCell width="15%">Status</TableCell>
          <TableCell width="5%">Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {KYCs?.map((kyc) => (
          <TableRow
            hover
            key={kyc?._id}
          >
            <TableCell>{kyc?.email}</TableCell>
            <TableCell>{kyc?.country}</TableCell>
            <TableCell>{(moment(kyc?.kyc?.timeSubmitted).format('DD/MM/YYYY'))}</TableCell>
            <TableCell>
              <h5>
                <span className={`badge ${kyc?.kyc?.status == 'Approved' ? 'badge-success' : kyc?.kyc?.status == 'Rejected' ? 'badge-danger' : 'badge-info'}`}>
                  {kyc?.kyc?.status}
                </span>
              </h5>
            </TableCell>
            <TableCell>
              <button type="button" className="btn btn-sm btn-primary mr-1" onClick={() => navigate(`/kyc/${kyc._id}`)}>
                <i className="fas fa-arrow-right" />
              </button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={totalKYCs || 0}
            page={page}
            onPageChange={(e, v) => setPage(v)}
            rowsPerPageOptions={[10, 25, 50]}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => setPageSize(e.target.value)}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}
