import React from 'react'

import store from 'redux/store'
import { Provider } from 'react-redux'
import MainRouter from 'routes/main'

function App() {
  return (
    <Provider store={store}>
      <MainRouter />
    </Provider>
  )
}

export default App
