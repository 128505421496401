import React from 'react'
import { FaBars } from 'react-icons/fa'

export default function Navbar() {
  const onLogout = () => {
    localStorage.removeItem('user')
    window.location.reload()
  }
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="/" role="button"><FaBars /></a>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a className="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="/auth" role="button" onClick={onLogout}>
            <i className="fas fa-sign-out-alt" />
          </a>
        </li>
      </ul>
    </nav>
  )
}
