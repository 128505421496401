import { addCoupon, updateCoupon } from 'api/coupons'
import {
  ContentHeader, CouponsTable, CustomModal, Toast
} from 'components'
import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { useDispatch } from 'react-redux'

export default function Coupons() {
  const dispatch = useDispatch()
  const [code, setCode] = useState('')
  const [type, setType] = useState('quantity')
  const [amount, setAmount] = useState(0)
  const [expiry, setExpiry] = useState(new Date().toISOString().slice(0, 10))
  const [shouldValidate, setShouldValidate] = useState(false)
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalUpdating, setModalUpdating] = useState(false)
  const [selectedCoupon, setSelectedCoupon] = useState()
  const openModal = () => setModalOpen(true)
  const refreshTable = useRef(null)

  const resetForm = () => {
    setCode('')
    setType('quantity')
    setExpiry(new Date().toISOString().slice(0, 10))
    setAmount(0)
    setModalUpdating(false)
  }

  const closeModal = () => {
    resetForm()
    setModalOpen(false)
  }

  const editCouponModal = (c) => {
    setCode(c.code)
    setAmount(c.amount)
    setExpiry(new Date(c.expiry).toISOString().slice(0, 10))
    setType(c.type)
    setSelectedCoupon(c._id)
    setModalUpdating(true)
    openModal()
  }

  const validExpiry = () => {
    const date = moment(expiry)
    return date.isValid() && date.isAfter(moment())
  }

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  useEffect(() => {
    setShouldValidate(false)
  }, [modalOpen])

  const handleForm = async () => {
    if (code === '' || type === '' || amount <= 0 || (expiry && !validExpiry()) || (type === 'percentage' && (amount > 100 || amount < 0.01))) {
      setShouldValidate(true)
    } else {
      dispatch(showLoader())
      try {
        const data = {
          code,
          type,
          amount
        }
        if (expiry) {
          data.expiry = new Date(expiry).toISOString()
        }
        if (modalUpdating) {
          await updateCoupon(data, selectedCoupon)
        } else {
          await addCoupon(data)
        }
        setModalOpen(false)
        resetForm()
        refreshTable.current()
      } catch (err) {
        const error = err?.response?.data
        if (error?.validationErrors) {
          setErrorMessage(error.validationErrors[0].msg)
        } else if (err?.response?.status === 409) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage('Something went wrong! Please refresh the page.')
        }
      }
      dispatch(hideLoader())
    }
  }

  const handleInputChange = (setter) => (e) => setter(e.target.value)

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title="Coupons" handleAdd={openModal} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <CouponsTable
                    handleEdit={editCouponModal}
                    handleError={setErrorMessage}
                    refresh={refreshTable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        handleClose={closeModal}
        handleForm={handleForm}
        headerText={modalUpdating ? 'Edit Coupon' : 'Add Coupon'}
        submitText={modalUpdating ? 'Update' : 'Add'}
        open={modalOpen}
      >
        <form className="needs-validation">
          <div className="form-group">
            <label htmlFor="code">
              Code
              <span className="text-danger">*</span>
            </label>
            <input
              id="code"
              value={code}
              onChange={handleInputChange(setCode)}
              autoComplete="off"
              type="text"
              className={`form-control ${shouldValidate && code === '' && 'is-invalid'}`}
            />
            {shouldValidate && code === '' && (<span className="error invalid-feedback">Invalid code value</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="type">
              Type
              <span className="text-danger">*</span>
            </label>
            <select value={type} onChange={handleInputChange(setType)} className="form-control">
              <option value="quantity">Quantity</option>
              <option value="percentage">Percentage</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="amount">
              Amount
              <span className="text-danger">*</span>
            </label>
            <input
              id="amount"
              value={amount}
              onChange={handleInputChange(setAmount)}
              autoComplete="off"
              type="number"
              className={`form-control ${shouldValidate && (amount <= 0 || (type === 'percentage' && amount > 100)) && 'is-invalid'}`}
            />
            {shouldValidate && (amount <= 0 || (type === 'percentage' && amount > 100)) && (<span className="error invalid-feedback">Invalid amount</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="expiry">
              Expiry
              <span className="text-danger">*</span>
            </label>
            <input
              id="expiry"
              value={expiry}
              onChange={handleInputChange(setExpiry)}
              autoComplete="off"
              type="date"
              className={`form-control ${shouldValidate && !validExpiry() && 'is-invalid'}`}
            />
          </div>
        </form>
      </CustomModal>
    </>
  )
}
