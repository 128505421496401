import axios from '../lib/axios'

export const getKYCs = async ({ limit, page }) => {
  const res = await axios.get('/kyc', {
    params: {
      limit,
      page,
      status: 'Approved'
    }
  })
  return res
}

export const getKYC = async (id) => {
  const res = await axios.get(`/kyc/user/${id}`)
  return res
}

export const changeKYCStatus = async ({ id, status }) => {
  const res = await axios.patch(`/kyc/${id}/status`, {
    status
  })
  return res
}
