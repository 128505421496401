import axios from '../lib/axios'

export const getFaq = async ({ limit, page }) => {
  const res = await axios.get('/faq', {
    params: {
      limit,
      page
    }
  })
  return res
}

export const addFaq = async (payload) => {
  const res = await axios.post('/faq', payload)
  return res
}

export const updateFaq = async (payload, id) => {
  const res = await axios.patch(`/faq/${id}`, payload)
  return res
}

export const deleteFaq = async ({ _id }) => {
  const res = await axios.delete(`/faq/${_id}`)
  return res
}
