import { addFaq, updateFaq } from 'api/faq'
import {
  ContentHeader, FAQsTable, CustomModal, Toast
} from 'components'
import React, { useEffect, useState, useRef } from 'react'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { useDispatch } from 'react-redux'

export default function FAQ() {
  const dispatch = useDispatch()
  const [answer, setAnswer] = useState('')
  const [question, setQuestion] = useState('')
  const [shouldValidate, setShouldValidate] = useState(false)
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalUpdating, setModalUpdating] = useState(false)
  const [selectedFaq, setSelectedFaq] = useState()
  const openModal = () => setModalOpen(true)
  const refreshTable = useRef(null)

  const resetForm = () => {
    setQuestion('')
    setAnswer('')
    setModalUpdating(false)
  }

  const closeModal = () => {
    resetForm()
    setModalOpen(false)
  }

  const editFaqModal = (e) => {
    setQuestion(e.question)
    setAnswer(e.answer)
    setSelectedFaq(e._id)
    setModalUpdating(true)
    openModal()
  }

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  useEffect(() => {
    setShouldValidate(false)
  }, [modalOpen])

  const handleForm = async () => {
    if (question === '' || answer === '') {
      setShouldValidate(true)
    } else {
      dispatch(showLoader())
      try {
        const data = {
          question,
          answer,
        }
        if (modalUpdating) {
          await updateFaq(data, selectedFaq)
        } else {
          await addFaq(data)
        }
        setModalOpen(false)
        resetForm()
        refreshTable.current()
      } catch (err) {
        const error = err?.response?.data
        if (error?.validationErrors) {
          setErrorMessage(error.validationErrors[0].msg)
        } else if (err?.response?.status === 409) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage('Something went wrong! Please refresh the page.')
        }
      }
      dispatch(hideLoader())
    }
  }

  const handleInputChange = (setter) => (e) => setter(e.target.value)

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title="FAQs" handleAdd={openModal} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <FAQsTable
                    handleEdit={editFaqModal}
                    handleError={setErrorMessage}
                    refresh={refreshTable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        handleClose={closeModal}
        handleForm={handleForm}
        headerText={modalUpdating ? 'Edit Faq' : 'Add Faq'}
        submitText={modalUpdating ? 'Update' : 'Add'}
        open={modalOpen}
      >
        <form className="needs-validation">
          <div className="form-group">
            <label htmlFor="question">
              Question
              <span className="text-danger">*</span>
            </label>
            <textarea
              id="question"
              value={question}
              onChange={handleInputChange(setQuestion)}
              autoComplete="off"
              type="text"
              className={`form-control ${shouldValidate && question === '' && 'is-invalid'}`}
            />
            {shouldValidate && question === '' && (<span className="error invalid-feedback">Invalid question value</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="answer">
              Answer
              <span className="text-danger">*</span>
            </label>
            <textarea
              id="answer"
              value={answer}
              onChange={handleInputChange(setAnswer)}
              autoComplete="off"
              type="text"
              className={`form-control ${shouldValidate && answer === '' && 'is-invalid'}`}
            />
            {shouldValidate && answer === '' && (<span className="error invalid-feedback">Invalid answer value</span>)}
          </div>
        </form>
      </CustomModal>
    </>
  )
}
