import React from 'react'

export default function ContentHeader({ title, handleAdd, handleBack }) {
  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          {handleBack
            ? (
              <button
                onClick={handleBack}
                type="button"
                className="btn bg-info ml-2 mr-3"
              >
                <i className="fas fa-arrow-left mr-2" />
                Back
              </button>
            ) : null}
          <div className="col-sm-6">
            <h1>{title}</h1>
          </div>
          {handleAdd
            ? (
              <div className="col-sm-6 text-right">
                <button
                  onClick={handleAdd}
                  type="button"
                  id="addButton"
                  className="btn bg-info"
                  data-toggle="modal"
                  data-target="#addModal"
                >
                  <i className="fas fa-plus mr-2" />
                  Add
                </button>
              </div>
            ) : null}
        </div>
      </div>
    </section>
  )
}
