import React from 'react'
import {
  CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis
} from 'recharts'

export default function LineChartSample({ title, data }) {
  return (
    <div className="card">
      <div className="card-header ui-sortable-handle">
        <h3 className="card-title">
          <i className="fas fa-chart-pie mr-2" />
          {title}
        </h3>
      </div>
      <div className="card-body">
        <LineChart
          width={1100}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="_id" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="count" stroke="#82ca9d" />
        </LineChart>
      </div>
    </div>
  )
}
