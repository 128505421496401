import axios from '../lib/axios'

export const getEmails = async ({ limit, page }) => {
  const res = await axios.get('/emails', {
    params: {
      limit,
      page
    }
  })
  return res
}

export const sendEmail = async (payload) => {
  const res = await axios.post('/emails/send', payload)
  return res
}
