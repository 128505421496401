import axios from '../lib/axios'

export const getCoupons = async ({ limit, page }) => {
  const res = await axios.get('/coupons', {
    params: {
      limit,
      page
    }
  })
  return res
}

export const addCoupon = async (payload) => {
  const res = await axios.post('/coupons', payload)
  return res
}

export const updateCoupon = async (payload, id) => {
  const res = await axios.patch(`/coupons/${id}`, payload)
  return res
}

export const deleteCoupon = async ({ _id }) => {
  const res = await axios.delete(`/coupons/${_id}`)
  return res
}
