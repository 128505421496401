import { addTestemonial, updateTestemonial } from 'api/testemonials'
import {
  ContentHeader, CustomModal, Toast
} from 'components'
import React, { useEffect, useState, useRef } from 'react'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { useDispatch } from 'react-redux'
import TestemonialsTable from 'components/tables/testemonialsTable'

export default function Testemonials() {
  const dispatch = useDispatch()
  const [author, setAuthor] = useState('')
  const [message, setMessage] = useState('')
  const [isShopper, setIsShopper] = useState(false)
  const [shouldValidate, setShouldValidate] = useState(false)
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalUpdating, setModalUpdating] = useState(false)
  const [selectedTestemonial, setSelectedTestemonial] = useState()
  const openModal = () => setModalOpen(true)
  const refreshTable = useRef(null)

  const resetForm = () => {
    setMessage('')
    setAuthor('')
    setModalUpdating(false)
  }

  const closeModal = () => {
    resetForm()
    setModalOpen(false)
  }

  const editTestemonialModal = (e) => {
    setMessage(e.message)
    setAuthor(e.author)
    setSelectedTestemonial(e._id)
    setModalUpdating(true)
    openModal()
  }

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  useEffect(() => {
    setShouldValidate(false)
  }, [modalOpen])

  const handleForm = async () => {
    if (message === '' || author === '') {
      setShouldValidate(true)
    } else {
      dispatch(showLoader())
      try {
        const data = {
          message,
          author,
          type: isShopper ? 'shopper' : 'traveller'
        }
        if (modalUpdating) {
          await updateTestemonial(data, selectedTestemonial)
        } else {
          await addTestemonial(data)
        }
        setModalOpen(false)
        resetForm()
        refreshTable.current()
      } catch (err) {
        const error = err?.response?.data
        if (error?.validationErrors) {
          setErrorMessage(error.validationErrors[0].msg)
        } else if (err?.response?.status === 409) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage('Something went wrong! Please refresh the page.')
        }
      }
      dispatch(hideLoader())
    }
  }

  const handleInputChange = (setter) => (e) => setter(e.target.value)

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title="Testemonials" handleAdd={openModal} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <TestemonialsTable
                    handleEdit={editTestemonialModal}
                    handleError={setErrorMessage}
                    refresh={refreshTable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        handleClose={closeModal}
        handleForm={handleForm}
        headerText={modalUpdating ? 'Edit Testemonial' : 'Add Testemonial'}
        submitText={modalUpdating ? 'Update' : 'Add'}
        open={modalOpen}
      >
        <form className="needs-validation">
          <div className="form-group">
            <label htmlFor="author">
              Author
              <span className="text-danger">*</span>
            </label>
            <textarea
              id="author"
              value={author}
              onChange={handleInputChange(setAuthor)}
              autoComplete="off"
              type="text"
              className={`form-control ${shouldValidate && author === '' && 'is-invalid'}`}
            />
            {shouldValidate && author === '' && (<span className="error invalid-feedback">Invalid author value</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="message">
              Message
              <span className="text-danger">*</span>
            </label>
            <textarea
              id="message"
              value={message}
              onChange={handleInputChange(setMessage)}
              autoComplete="off"
              type="text"
              className={`form-control ${shouldValidate && message === '' && 'is-invalid'}`}
            />
            {shouldValidate && message === '' && (<span className="error invalid-feedback">Invalid message value</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="message">
              User type
              <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              value={isShopper}
              onChange={() => setIsShopper(!isShopper)}
            >
              <option value={false}>Traveller</option>
              <option value>Shopper</option>
            </select>
            {shouldValidate && message === '' && (<span className="error invalid-feedback">Invalid message value</span>)}
          </div>

        </form>
      </CustomModal>
    </>
  )
}
