/* eslint-disable jsx-a11y/anchor-is-valid */
import { changeKYCStatus, getKYC } from 'api/kyc'
import {
  ContentHeader, Toast,
  KYCDetailsCard
} from 'components'
import ModalImage from 'react-modal-image'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { hideLoader, showLoader } from 'redux/slices/loader'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Swal from 'components/swal'

export default function KYCDetails() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [KYC, setKYC] = useState({})
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const loadData = useCallback(async () => {
    const userId = params.id
    dispatch(showLoader())
    try {
      const response = await getKYC(userId)
      setKYC(response.data)
    } catch (err) {
      setErrorMessage('KYC user not found')
    }
    dispatch(hideLoader())
  }, [dispatch, params.id])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  const handleStatusChange = async (status) => {
    const { isConfirmed } = await Swal.fire({
      title: status === 'Approved' ? 'Approve this User?' : 'Reject this user?',
      icon: status === 'Approved' ? 'question' : 'warning',
      showCancelButton: true,
      confirmButtonText: status === 'Approved' ? 'Yes! Approve' : 'Reject',
      confirmButtonColor: status === 'Approved' ? '#28a745' : '#dc3545',
    })
    if (isConfirmed) {
      dispatch(showLoader())
      try {
        const response = await changeKYCStatus({
          id: params.id,
          status
        })
        setKYC(response?.data)
        dispatch(hideLoader())
        Swal.fire({
          icon: 'success',
          title: status === 'Approved' ? 'Approved!' : 'Rejected!',
          text: status === 'Approved' ? 'User approved successfully!' : 'User rejected successfully!',
          showConfirmButton: false,
          timer: 1000
        })
      } catch (err) {
        dispatch(hideLoader())
        setErrorMessage('Something went wrong! Please refresh the page.')
      }
    }
  }

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title="" handleBack={() => navigate('/kyc')} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title mt-1">KYC Details</h3>
                  <div className="card-tools">
                    <button
                      onClick={() => handleStatusChange('Rejected')}
                      type="button"
                      className="btn btn-danger btn-sm mr-2"
                      disabled={KYC?.kyc?.status === 'Rejected'}
                    >
                      <i className="fas fa-times mr-2" />
                      Reject
                    </button>
                    <button
                      onClick={() => handleStatusChange('Approved')}
                      type="button"
                      className="btn btn-success btn-sm"
                      disabled={KYC?.kyc?.status === 'Approved'}
                    >
                      <i className="fas fa-check mr-2" />
                      Approve
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <KYCDetailsCard kyc={KYC} />
                  <div className="mt-3" />
                  <ImageList sx={{ height: 100 }} cols={5} rowHeight={100}>
                    {KYC?.kyc?.documents.map((document) => (
                      <ImageListItem key={document}>
                        <ModalImage
                          small={document}
                          large={document}
                          hideDownload
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
