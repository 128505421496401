import { Wrapper } from 'components'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  Coupons,
  Dashboard,
  Emails,
  Stores,
  KYC,
  KYCDetails,
  FAQ,
} from 'screens'
import Subscribers from 'screens/subscribers'
import Testemonials from 'screens/testemonials'

function MainRouter() {
  return (
    <Wrapper>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/emails" element={<Emails />} />
        <Route path="/subscribers" element={<Subscribers />} />
        <Route path="/stores" element={<Stores />} />
        <Route path="/kyc" element={<KYC />} />
        <Route path="/kyc/:id" element={<KYCDetails />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/testemonials" element={<Testemonials />} />
      </Routes>
    </Wrapper>
  )
}

export default MainRouter
