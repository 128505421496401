import { barData } from 'constants/barChartData'
import React from 'react'
import {
  Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis
} from 'recharts'

export default function BarChartSample({ title, data }) {
  return (
    <div className="card">
      <div className="card-header ui-sortable-handle">
        <h3 className="card-title">
          {title}
        </h3>
      </div>
      <div className="card-body">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="_id" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#8884d8" />
          {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
      </div>
    </div>
  )
}
