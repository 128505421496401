/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

export default function Sidebar() {
  const user = JSON.parse(localStorage.getItem('user'))
  const { pathname } = useLocation()
  const isPath = (path) => pathname.includes(path)
  const [refreshForcer, setRefreshForcer] = useState(true)

  useEffect(() => {
    setRefreshForcer(false)
  }, [pathname])

  useEffect(() => {
    if (!refreshForcer)
      setRefreshForcer(true)
  }, [refreshForcer])
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 min-vh-100 position-fixed ">
      <a href="/" className="brand-link">
        <img src="/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
        <span className="brand-text font-weight-light">Carry On</span>
      </a>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
          </div>
          <div className="info">
            <a href="/" className="d-block">{user.firstName} {user.lastName}</a>
          </div>
        </div>
        <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div>
        {refreshForcer ? (
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                <NavLink to="/dashboard/" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Dashboard
                  </p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/coupons/" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                  <i className="nav-icon fas fa-ticket-alt" />
                  <p>
                    Coupons
                  </p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/emails/" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                  <i className="nav-icon fas fa-mail-bulk" />
                  <p>
                    Emails
                  </p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/subscribers/" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                  <i className="nav-icon fas fa-bell" />
                  <p>
                    Subscribers
                  </p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to="/stores/" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                  <i className="nav-icon fas fa-store-alt" />
                  <p>
                    Stores
                  </p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to="/kyc/" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                  <i className="nav-icon fas fa-id-card" />
                  <p>
                    KYC
                  </p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to="/faq/" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                  <i className="nav-icon fas fa-id-card" />
                  <p>
                    FAQ
                  </p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to="/testemonials/" className={({ isActive }) => `nav-link ${isActive ? " active" : ""}`}>
                  <i className="nav-icon fas fa-comments" />
                  <p>
                    Testemonials
                  </p>
                </NavLink>
              </li>
            </ul>
          </nav>
        ) : null
        }
      </div>
    </aside>
  )
}