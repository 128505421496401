import React from 'react'
import {
  Table, TableBody, TableCell, TableRow, Typography, TableContainer, Paper
} from '@mui/material'
import { dateTimeFormat } from 'utils/dates'

export default function KYCDetailsCard({
  kyc
}) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                First Name:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                {kyc?.firstName}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                Last Name:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                {kyc?.lastName}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                Email:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                {kyc?.email}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                Phone:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                +
                {kyc?.phone}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                Country:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                {kyc?.country}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                Submitted At:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                {kyc?.kyc?.timeSubmitted && dateTimeFormat(kyc?.kyc?.timeSubmitted)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                Document Type:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                {kyc?.kyc?.documentType}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                Status:
              </Typography>
            </TableCell>
            <TableCell>
              <h5>
                <span className={`badge ${kyc?.kyc?.status == 'Approved' ? 'badge-success' : kyc?.kyc?.status == 'Rejected' ? 'badge-danger' : 'badge-info'}`}>
                  {kyc?.kyc?.status}
                </span>
              </h5>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
