import axios from '../lib/axios'

export const getTestemonials = async ({ limit, page }) => {
  const res = await axios.get('/testemonials', {
    params: {
      limit,
      page
    }
  })
  return res
}

export const addTestemonial = async (payload) => {
  const res = await axios.post('/testemonials', payload)
  return res
}

export const updateTestemonial = async (payload, id) => {
  const res = await axios.patch(`/testemonials/${id}`, payload)
  return res
}

export const deleteTestemonial = async ({ _id }) => {
  const res = await axios.delete(`/testemonials/${_id}`)
  return res
}
