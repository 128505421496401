import { sendEmail } from 'api/emails'
import {
  ContentHeader, CustomModal, EmailsTable, Toast
} from 'components'
import React, { useEffect, useState, useRef } from 'react'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { useDispatch } from 'react-redux'

export default function Emails() {
  const dispatch = useDispatch()
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [shouldValidate, setShouldValidate] = useState(false)
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => setModalOpen(true)
  const refreshTable = useRef(null)

  const resetForm = () => {
    setSubject('')
    setBody('')
  }

  const closeModal = () => {
    resetForm()
    setModalOpen(false)
  }

  useEffect(() => {
    if (errorMessage !== '') {
      setToastOpen(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (toastOpen === false) {
      setErrorMessage('')
    }
  }, [toastOpen])

  useEffect(() => {
    setShouldValidate(false)
  }, [modalOpen])

  const handleForm = async () => {
    if (subject.trim() === '' || body.trim() === '') {
      setShouldValidate(true)
    } else {
      dispatch(showLoader())
      try {
        const data = {
          subject,
          body
        }
        await sendEmail(data)
        setModalOpen(false)
        resetForm()
        refreshTable.current()
      } catch (err) {
        const error = err?.response?.data
        if (error?.validationErrors) {
          setErrorMessage(error.validationErrors[0].msg)
        } else if (err?.response?.status === 409) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage('Something went wrong! Please refresh the page.')
        }
      }
      dispatch(hideLoader())
    }
  }

  const handleInputChange = (setter) => (e) => setter(e.target.value)

  return (
    <>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={errorMessage} type="error" />
      <ContentHeader title="Emails" hasAdd handleAdd={openModal} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <EmailsTable
                    refresh={refreshTable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        handleClose={closeModal}
        handleForm={handleForm}
        headerText="Send Email"
        submitText="Send"
        open={modalOpen}
      >
        <form className="needs-validation">
          <div className="form-group">
            <label htmlFor="subject">
              Subject
              <span className="text-danger">*</span>
            </label>
            <input
              id="subject"
              value={subject}
              onChange={handleInputChange(setSubject)}
              autoComplete="off"
              type="text"
              className={`form-control ${shouldValidate && subject.trim() === '' && 'is-invalid'}`}
            />
            {shouldValidate && subject.trim() === '' && (<span className="error invalid-feedback">Subject must not be empty</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="body">
              Body
              <span className="text-danger">*</span>
            </label>
            <input
              id="body"
              value={body}
              onChange={handleInputChange(setBody)}
              autoComplete="off"
              type="text"
              className={`form-control ${shouldValidate && body.trim() === '' && 'is-invalid'}`}
            />
            {shouldValidate && body.trim() === '' && (<span className="error invalid-feedback">Body must not be empty</span>)}
          </div>
        </form>
      </CustomModal>
    </>
  )
}
