import {
  Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow
} from '@mui/material'
import { deleteFaq, getFaq } from 'api/faq'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader } from 'redux/slices/loader'
import { deleteTestemonial, getTestemonials } from 'api/testemonials'
import Swal from '../swal'

export default function TestemonialsTable({ handleEdit, handleError, refresh }) {
  const dispatch = useDispatch()

  const [testemonials, setTestemonials] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [totalTestemonials, setTotalTestemonials] = useState(0)

  const loadData = useCallback(async () => {
    dispatch(showLoader())
    try {
      const response = await getTestemonials({ limit: pageSize, page: page + 1 })
      setTestemonials(response.data.docs)
      setTotalTestemonials(response.data.totalDocs)
    } catch (err) {
      console.log(err)
    }
    dispatch(hideLoader())
  }, [page, pageSize, dispatch])

  useEffect(() => {
    refresh.current = loadData
  }, [loadData, refresh])

  useEffect(() => {
    loadData()
  }, [loadData, page, pageSize])

  const handleDelete = async (c) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    })
    if (isConfirmed) {
      dispatch(showLoader())
      try {
        await deleteTestemonial({
          _id: c._id
        })
        dispatch(hideLoader())
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'Testemonial deleted successfully',
          showConfirmButton: false,
          timer: 1000
        })
      } catch (err) {
        dispatch(hideLoader())
        const error = err.response.data
        if (error.validationErrors) {
          handleError(error.validationErrors[0].msg)
        } else {
          handleError('Something went wrong! Please refresh the page.')
        }
      }
      loadData()
    }
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="20%">Author</TableCell>
          <TableCell width="20%">Message</TableCell>
          <TableCell width="20%">Type</TableCell>
          <TableCell width="20%">Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {testemonials?.map((e) => (
          <TableRow
            hover
            key={e?._id}
          >
            <TableCell>{e?.author.slice(0, 70)}{e?.author.length > 70 ? '...' : ''}</TableCell>
            <TableCell>{e?.message.slice(0, 70)}{e?.message.length > 70 ? '...' : ''}</TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>{e?.type}</TableCell>
            <TableCell>
              <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => handleEdit(e)}>
                <i className="fas fa-edit fa-fw" />
              </button>
              <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDelete(e)}>
                <i className="fas fa-trash fa-fw" />
              </button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={totalTestemonials || 0}
            page={page}
            onPageChange={(e, v) => setPage(v)}
            rowsPerPageOptions={[10, 25, 50]}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => setPageSize(e.target.value)}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}
