import { login } from 'api/auth'
import React, { useState } from 'react'
import {
  FaEnvelope,
  FaLock,
} from 'react-icons/fa'

export default function LoginScreen() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [shouldValidate, setShouldValidate] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleChange = setter => event => {
    setErrorMessage('')
    setter(event.target.value)
  }

  const onLogin = async (e) => {
    e.preventDefault()
    if (email === '' || password.length < 8) {
      setShouldValidate(true)
      setErrorMessage('Invalid Values')
    } else {
      try {
        const { data: user } = await login({
          email,
          password,
        })
        localStorage.setItem('user', JSON.stringify(user))
        window.location.reload()
      } catch (err) {
        if (err.response?.status === 400) {
          setErrorMessage(err.response.data.message)
        } else {
          setErrorMessage('Something went wrong, please try again later')
        }
      }
    }
  }

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a href="/" className="h1">Carry On</a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Please sign in</p>
            <form action="/auth/" method="post">
              <div className="input-group mb-3">
                <input type="email" className={`form-control ${shouldValidate && email === '' ? 'is-invalid' : ''}`} placeholder="Email" onChange={handleChange(setEmail)} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <FaEnvelope />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input type="password" className={`form-control ${shouldValidate && email === '' ? 'is-invalid' : ''}`} placeholder="Password" onChange={handleChange(setPassword)} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <FaLock />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <span className="text-danger">
                    &nbsp;
                    {errorMessage}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button type="submit" className="btn btn-primary btn-block" onClick={onLogin}>Sign In</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
