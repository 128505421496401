import {
  Button,
  Switch,
  Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow
} from '@mui/material'
import { getSignedUrl, getStores, updateStore } from 'api/stores'
import axios from 'axios'
import FormData from 'form-data'
import compress from 'lib/imageCompress'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader } from 'redux/slices/loader'
import Swal from '../swal'
import styles from './styles.module.scss'

export default function StoresTable({ handleError, refresh }) {
  const dispatch = useDispatch()
  const [imageName, setImageName] = useState('image.png')
  const [logoName, setLogoName] = useState('logo.png')
  const [stores, setStores] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [totalStores, setTotalStores] = useState(0)

  const loadData = useCallback(async () => {
    dispatch(showLoader())
    try {
      const response = await getStores({ limit: pageSize, page: page + 1 })
      setStores(response?.data?.docs)
      setTotalStores(response?.data?.totalDocs)
    } catch (err) {
      console.log(err)
    }
    dispatch(hideLoader())
  }, [page, pageSize]) // change according to page number

  useEffect(() => {
    refresh.current = loadData
  }, [loadData, refresh])

  useEffect(() => {
    loadData()
  }, [loadData, page, pageSize])

  const uploadImage = async (payload, img) => {
    dispatch(showLoader())
    let res
    try {
      fetch(img)
        .then(result => result.blob())
        .then(async blob => {
          const file = new File([blob], 'File name', { type: 'image/png' })
          const presignedPostData = await getSignedUrl({ imageName, id: payload?._id })
          const { imageUrl } = presignedPostData.data
          const uploadUrl = presignedPostData.data.data.url
          const formData = new FormData()
          Object.keys(presignedPostData.data.data.fields).forEach(key => {
            formData.append(key, presignedPostData.data.data.fields[key])
          })
          formData.append('file', file)
          axios.defaults.headers.post['Content-Type'] = presignedPostData.data.data.fields['Content-Type']
          axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
          const [_, two] = await Promise.all([
            axios.post(uploadUrl, formData),
            updateStore({ ...payload, imageUrl })
          ])
          res = two
          loadData().then(() => Swal.fire({
            icon: 'success',
            title: 'Image Uploaded!',
            text: 'Local Store updated successfully',
            showConfirmButton: false,
            timer: 1000
          }))
        })
    } catch (e) {
      console.log(e)
      handleError(e?.response?.data?.message)
    } finally {
      dispatch(hideLoader())
    }
  }

  const uploadLogo = async (payload, lg) => {
    let res
    try {
      dispatch(showLoader())
      fetch(lg)
        .then(result => result.blob())
        .then(async blob => {
          const file = new File([blob], 'File name', { type: 'image/png' })
          const presignedPostData = await getSignedUrl({ imageName: logoName, id: payload?._id })
          const { imageUrl } = presignedPostData.data
          const uploadUrl = presignedPostData?.data?.data?.url
          const formData = new FormData()
          Object.keys(presignedPostData.data.data.fields).forEach(key => {
            formData.append(key, presignedPostData.data.data.fields[key])
          })
          formData.append('file', file)
          axios.defaults.headers.post['Content-Type'] = presignedPostData.data.data.fields['Content-Type']
          axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
          const [_, two] = await Promise.all([
            axios.post(uploadUrl, formData),
            updateStore({ ...payload, logoUrl: imageUrl })
          ])
          res = two
          loadData().then(
            () => Swal.fire({
              position: 'bottom',
              icon: 'success',
              title: 'Logo Changed!',
              text: 'Local Store updated successfully',
              showConfirmButton: false,
              timer: 1000
            })
          )
        })
    } catch (e) {
      console.log(e)
      if (e.validationErrors) {
        handleError(e.validationErrors[0].msg)
      } else {
        handleError('Something went wrong! Please refresh the page.')
      }
    } finally {
      dispatch(hideLoader())
    }
  }

  const handleSwitch = useCallback(async (payload) => {
    dispatch(showLoader())
    try {
      await updateStore(payload)
      loadData()
      Swal.fire({
        icon: 'success',
        title: 'Switched!',
        text: 'Local Store updated successfully',
        showConfirmButton: false,
        timer: 1000
      })
    } catch (err) {
      console.log(err)
      if (err.validationErrors) {
        handleError(err.validationErrors[0].msg)
      } else {
        handleError('Something went wrong! Please refresh the page.')
      }
    } finally {
      dispatch(hideLoader())
    }
  }, [dispatch, loadData])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="20%">Link</TableCell>
          <TableCell width="10%">Show / Hide</TableCell>
          <TableCell width="35%">Featured Image</TableCell>
          <TableCell width="35%">Logo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {stores?.map((s, i) => (
          <TableRow
            hover
            key={s?.link}
          >
            <TableCell><a href={`https://${s?.link}`} target="_blank" rel="noreferrer noopener">{s?.link}</a></TableCell>
            <TableCell>
              <Switch
                color="warning"
                checked={s?.show}
                onChange={() => {
                  handleSwitch({
                    link: s?.link, show: !s?.show, imageUrl: s?.imageUrl, logoUrl: s?.logoUrl
                  })
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </TableCell>
            <TableCell>
              <div className={styles.updatePhoto}>
                <div className={styles.imageContainer}>
                  <img src={(!s?.imageUrl) || (s?.imageUrl == '') ? 'https://www.e-designeroutlet.com/media/catalog/product/cache/88693066e778cee687b8a895a940c49c/3/Z/3Z1M71.1J36Z_MAG-41yC51acioL..jpg' : s?.imageUrl} alt="nationality" />
                </div>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Image
                  <br />
                  <input
                    type="file"
                    hidden
                    onChange={event => {
                      compress(setImageName, (img) => uploadImage(s, img), 1)(event)
                    }}
                  />
                </Button>
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.updatePhoto}>
                <div className={styles.imageContainer}>
                  <img src={(!s?.logoUrl) || (s?.logoUrl == '') ? 'https://www.e-designeroutlet.com/media/catalog/product/cache/88693066e778cee687b8a895a940c49c/3/Z/3Z1M71.1J36Z_MAG-41yC51acioL..jpg' : s?.logoUrl} alt="nationality" />
                </div>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Image
                  <br />
                  <input
                    type="file"
                    hidden
                    onChange={event => {
                      compress(setLogoName, (img) => uploadLogo(s, img), 1)(event)
                    }}
                  />
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={totalStores || 0}
            page={page}
            onPageChange={(e, v) => setPage(v)}
            rowsPerPageOptions={[10, 25, 50]}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => setPageSize(e.target.value)}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}
