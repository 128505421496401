import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import AuthNav from './auth'
import DashboardNav from './dashboard'
import packageJson from '../../package.json'
// import { getUserDetails } from 'api/user'
// import { setProfile } from 'redux/slices/profile'

function MainRouter() {
  // SAMPLE for screens that require authentication
  // const [error, setError] = useState(null)
  // const [loading, setLoading] = useState(true)
  // const dispatch = useDispatch()

  // const fetchUserDetails = useCallback(() => {
  //   setLoading(true)
  //   getUserDetails().then(res => {
  //     if (res?.data) {
  //       dispatch(setProfile(res.data))
  //     }
  //   }).catch(err => {
  //     setError(err.message)
  //   }).finally(() => {
  //     setLoading(false)
  //   })
  // }, [dispatch])

  // useEffect(() => {
  //   fetchUserDetails()
  // }, [fetchUserDetails])
  const isLoggedIn = JSON.parse(localStorage.getItem('user'))
  console.log(packageJson.version)
  return (
    <Router>
      <Routes>
        <Route path="/auth/*" element={!isLoggedIn ? <AuthNav /> : <Navigate to="/" />} />
        <Route path="/*" element={isLoggedIn ? <DashboardNav /> : <Navigate to="/auth" />} />
      </Routes>
    </Router>
  )
}

export default MainRouter
