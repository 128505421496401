/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import { ContentHeader } from 'components'
import AreaChartSample from 'components/charts/areaChart'
import BarChart from 'components/charts/barChart'
import LineChart from 'components/charts/lineChart'
import ScatterPlotSample from 'components/charts/scatterPlot'
import { getDashboard } from 'api/dashboard'
import './styles.scss'

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState({})
  useEffect(() => {
    getDashboard().then(res => setDashboardData(res?.data?.data))
  }, [])
  const {
    shopperMetrics,
    transactionMetrics,
    travelerMetrics,
    userActivity,
    geographicalData,
  } = dashboardData

  return (
    <>
      <ContentHeader title="Dashboard" />
      <section className="content">
        <div className="container-fluid">
          <h2 className="pairs-title">User Activity</h2>
          <div className="pairs-container">
            <div className="pair">
              <p>Active Users Today</p>
              <p>{userActivity?.dailyActiveUsers}</p>
            </div>
            <div className="pair">
              <p>Active Users This Month</p>
              <p>{userActivity?.monthlyActiveUsers}</p>
            </div>
            <div className="pair">
              <p>Active Shoppers</p>
              <p>{shopperMetrics?.activeShoppers}</p>
            </div>
            <div className="pair">
              <p>Active Travelers</p>
              <p>{travelerMetrics?.activeTravelers}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <LineChart title="Monthly New Users" data={userActivity?.userGrowth} />
            </div>
          </div>
          <h2 className="pairs-title">Transaction Metrics</h2>
          <div className="pairs-container">
            <div className="pair">
              <p>Number of Transactions</p>
              <p>{transactionMetrics?.numberOfTransactions}</p>
            </div>
            <div className="pair">
              <p>Total Transactions Value</p>
              <p>{transactionMetrics?.transactionValue}$</p>
            </div>
            <div className="pair">
              <p>Total Travellers Earnings</p>
              <p>{travelerMetrics?.totalTravelerEarnings}$</p>
              <span className="note">(Offer amount - Orders Prices)</span>
            </div>
          </div>
          <h2 className="pairs-title">Operational Metrics</h2>
          <div className="pairs-container">
            <div className="pair">
              <p>Cancelled Offers</p>
              <p>{travelerMetrics?.canceledOffers}</p>
            </div>
            <div className="pair">
              <p>Completed Orders</p>
              <p>{travelerMetrics?.deliveredOffers}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <BarChart title="Popular Order Item Categories" data={shopperMetrics?.popularItems} />
            </div>
          </div>
          <br />
          <br />
          <h2 className="pairs-title">Geographics</h2>
          <div className="row">
            <div className="col-md-6">
              <BarChart title="Most Ordered-From Countries" data={geographicalData?.topRegionsByOrders} />
            </div>
            <div className="col-md-6">
              <BarChart title="Most Travelled-From Countries" data={geographicalData?.topRegionsByTrips} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <BarChart title="Popular Travellers Routes" data={geographicalData?.popularTravelRoutes?.map(i => ({ ...i, _id: `${i._id.fromCountry} -> ${i._id.toCountry}` }))} />
            </div>
            <div className="col-md-6">
              <BarChart title="Popular Ordered Routes" data={geographicalData?.popularOrderRoutes?.map(i => ({ ...i, _id: `${i._id.fromCountry} -> ${i._id.toCountry}` }))} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
