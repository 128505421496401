import imageCompression from 'browser-image-compression'

const compress = (imageSetter, setter, limit) => async (e) => {
  if (e.target.files.length > limit) return alert(`The limit of uploaded files is ${limit}`)
  const filesObj = e.target.files
  imageSetter(filesObj[0]?.name)
  const files = await Promise.all(
    Object.values(filesObj).map(async (img) => {
      const resized = await imageCompression(img, {
        maxSizeMB: 0.25,
        maxWidthOrHeight: 1920,
      })
      return imageCompression.getDataUrlFromFile(resized)
    }),
  ).catch(err => { console.log(err) })

  setter(files)
}

export default compress
