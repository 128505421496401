import axios from '../lib/axios'

export const getSubscribers = async ({ limit, page }) => {
  const res = await axios.get('/profiles/subscribers', {
    params: {
      limit,
      page
    }
  })
  return res
}
