import React from 'react'
import { Modal } from '@mui/material'

export default function CustomModal({
  handleClose, children, open,
  headerText, submitText, handleForm
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 id="modalTitle" className="modal-title">{headerText}</h4>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {children}
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleForm}>{submitText}</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
