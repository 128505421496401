import {
  Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow
} from '@mui/material'
import { getSubscribers } from 'api/subscribers'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader } from 'redux/slices/loader'

export default function SubscribersTable({ refresh }) {
  const dispatch = useDispatch()

  const [subscribers, setSubscribers] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [totalSubs, setTotalSubs] = useState(0)

  const loadData = useCallback(async () => {
    dispatch(showLoader())
    try {
      const response = await getSubscribers({ limit: pageSize, page: page + 1 })
      setSubscribers(response?.data?.docs)
      setTotalSubs(response?.data?.totalDocs)
    } catch (err) {
      console.log(err)
    }
    dispatch(hideLoader())
  }, [page, pageSize]) // change according to page number

  useEffect(() => {
    refresh.current = loadData
  }, [loadData, refresh])

  useEffect(() => {
    loadData()
  }, [loadData, page, pageSize])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="50%">Full Name</TableCell>
          <TableCell width="50%">Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subscribers?.map((subscriber) => (
          <TableRow
            hover
            key={subscriber?._id}
          >
            <TableCell>{`${subscriber?.firstName} ${subscriber?.lastName}`}</TableCell>
            <TableCell>{subscriber?.email}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={totalSubs || 0}
            page={page}
            onPageChange={(e, v) => setPage(v)}
            rowsPerPageOptions={[10, 25, 50]}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => setPageSize(e.target.value)}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}
