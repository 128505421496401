import { createSlice } from '@reduxjs/toolkit'

export const sampleSlice = createSlice({
  name: 'sample',
  initialState: {
    foo: 'bar',
    baz: 123,
  },
  reducers: {
    setSample: (state, action) => {
      state = action.payload
    },
    setBaz: (state, action) => {
      state.baz = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSample, setBaz } = sampleSlice.actions

export default sampleSlice.reducer
