import axios from '../lib/axios'

export const getStores = async ({ limit, page }) => {
  const res = await axios.get('/stores', {
    params: {
      limit,
      page
    }
  })
  return res
}

// export const addStore = async (payload) => {
//   const res = await axios.post('/stores', payload)
//   return res
// }

export const updateStore = async (payload) => {
  const res = await axios.patch(`/stores/${payload.link}`, payload)
  return res
}

export const deleteStore = async (payload) => {
  const res = await axios.delete(`/stores/${payload.link}`, payload)
  return res
}

export const getSignedUrl = async ({ imageName, id }) => {
  const res = await axios.get(`/stores/getS3SignedUrl?fileName=${imageName}&code=${id}`)
  return res
}
