import React, {
  useState, useEffect, useCallback
} from 'react'
import { showLoader, hideLoader } from 'redux/slices/loader'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow
} from '@mui/material'
import { getEmails } from 'api/emails'

export default function EmailsTable({ refresh }) {
  const dispatch = useDispatch()

  const [emails, setEmails] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [totalEmails, setTotalEmails] = useState(0)

  const loadData = useCallback(async () => {
    dispatch(showLoader())
    try {
      const response = await getEmails({ limit: pageSize, page: page + 1 })
      setEmails(response.data.docs)
      setTotalEmails(response.data.totalDocs)
    } catch (err) {
      console.log(err)
    }
    dispatch(hideLoader())
  }, [page, pageSize]) // change according to page number

  useEffect(() => {
    refresh.current = loadData
  }, [loadData, refresh])

  useEffect(() => {
    loadData()
  }, [loadData, page, pageSize])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="20%">Subject</TableCell>
          <TableCell width="60%">Body</TableCell>
          <TableCell width="20%">Date Sent</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {emails?.map((email) => (
          <TableRow
            hover
            key={email?._id}
          >
            <TableCell>{email?.subject}</TableCell>
            <TableCell>{email?.body}</TableCell>
            <TableCell>{(moment(email?.createdAt).format('DD/MM/YYYY'))}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={totalEmails || 0}
            page={page}
            onPageChange={(e, v) => setPage(v)}
            rowsPerPageOptions={[10, 25, 50]}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => setPageSize(e.target.value)}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}
